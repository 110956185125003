import {baseRequestClient} from "@/request/request_client";
import {IList, IListResult} from "@/models/base_model";
import {IProjectItem} from "@/models/project_model";

export default class ApiProject {
    /**
     * 获取项目列表
     * GET
     */
    static getProjectList = async (params) => {
        return await baseRequestClient.get<IList<IProjectItem>>('/admin/decoration.Ad/projectList', params)
    }

    /**
     * 项目添加
     * POST
     * @param data
     */
    static projectAdd = async (data) => {
        return await baseRequestClient.post('/admin/decoration.Ad/projectAdd', data)
    }

    /**
     * 项目编辑
     * POST
     * @param data
     */
    static projectEdit = async (data) => {
        return await baseRequestClient.post('/admin/decoration.Ad/projectEdit', data)
    }

    /**
     * 共享商圈表单列表
     * @param params ?page=&limit=&phone=&name=
     */
    static getBusinessFormList = async (params) => {
        return await baseRequestClient.get<IListResult<any>>('/admin/logistics.Feedback/businessFormList', params)
    }

    /**
     * 共仓物流表单列表
     * @param params ?page=&limit=&phone=&name=&type=
     */
    static getLogisticsFormList = async (params) => {
        return await baseRequestClient.get<IListResult<any>>('/admin/logistics.Feedback/logisticsFormList', params)
    }

    /**
     * 共享空间表单列表
     * @param params ?page=&limit=&phone=&name=&type=
     */
    static getSpaceFormList = async (params) => {
        return await baseRequestClient.get<IListResult<any>>('/admin/logistics.Feedback/spaceFormList', params)
    }

    /**
     * 供应商表单列表
     * @param params ?page=&limit=&phone=&name=&company_name=
     */
    static getSupplierFormList = async (params) => {
        return await baseRequestClient.get<IListResult<any>>('/admin/logistics.Feedback/supplierFormList', params)
    }
}
